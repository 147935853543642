
import { ClientJS } from 'clientjs';
import { useState, useEffect } from 'react';
import { createHashRouter, RouterProvider, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocalStorage } from '@uidotdev/usehooks';
import mixpanel from 'mixpanel-browser';

import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { fetchUserInfo, PROD_HOSTS } from './common.js';
import { UserInfo, Messages, Organizations } from './context.js';
import { CenterBox } from './components.js';

import AudioProvider from './audio/AudioProvider.js';
import HomeView from './HomeView.js'
import LoginView from './user/LoginView.js';
import ErrorFallback from './ErrorFallback.js';
import ProtectedRoute from './ProtectedRoute.js';
import PublicListenView from './PublicListenView.js';
import SecretLoginView from './user/SecretLoginView.js';

if (PROD_HOSTS.some(h => h === window.location.host)) {
  // voicetopics-customer-prod
  mixpanel.init('9dd0d91dcadb99de81dfd0f2f162932c', {
    track_pageview: true,
    persistence: 'localStorage',
  });
} else {
  // voicetopics-customer-dev
  mixpanel.init('9ec400cef7dc8bbef1d3f59b6ad3b205', {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
  });
}

mixpanel.identify(new ClientJS().getFingerprint());

const WrapView = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    mixpanel.track('Page view', { pathname: location.pathname });
  }, [location]);

  return (
    <ErrorBoundary
        onError={(error, info) => {
          mixpanel.track('Uncaught error', error);
        }}
        FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  );
};

const CustomerApp = () => {

  const router = createHashRouter([
        { path: '/', element: <WrapView><HomeView /></WrapView> },
        { path: '/listen/:id', element: <WrapView><PublicListenView /></WrapView> },
        {
          element:
              <ProtectedRoute
                  storeTarget={false}
                  rules={[{
                      predicate: ({userInfo}) => !userInfo?.unauthenticated,
                      newPath: '/app',
                      newHash: '/',
                  }]}/>,
          children: [
            { path: '/login', element: <WrapView><LoginView /></WrapView> },
            { path: '/login/:secretKey', element: <WrapView><SecretLoginView /></WrapView> },
          ]
        },
        { path: '*', element: <ErrorFallback /> },
      ]);

  const [playbackSpeed, setPlaybackSpeed] = useLocalStorage('playbackSpeed', 1);

  const [message, setMessage] = useState(null);
  const handleCloseMessage = () => setMessage(null);

  const [ userInfo, setUserInfo ] = useState(null);

  useEffect(() => {
    fetchUserInfo().then((userData) => {
      //console.log({ userData });
      setUserInfo(userData);
    });
  }, []);

  return (
    <UserInfo.Provider value={{ userInfo, setUserInfo }}>
      <Organizations.Provider value={{ organizations: [] }}>
        <Messages.Provider value={{ message, setMessage }}>
          <AudioProvider playbackSpeed={playbackSpeed} setPlaybackSpeed={setPlaybackSpeed}>
            <RouterProvider router={router} />
            {message &&
                <Snackbar
                    open
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    autoHideDuration={message.duration ?? 10000}
                    onClose={handleCloseMessage}>
                  <Alert {...message} onClose={handleCloseMessage}>
                    <CenterBox sx={{ height: '100%' }}>
                      {message.children}
                    </CenterBox>
                  </Alert>
                </Snackbar>}
          </AudioProvider>
        </Messages.Provider>
      </Organizations.Provider>
    </UserInfo.Provider>
  );
};

export default CustomerApp;
